<template>
  <SubPageWrapper>
    <template #loader>
      <SlOverlay :show="isProcessing" />
    </template>

    <template #left-col>
      <SlTabList
        v-model="tabModel"
        :tabs="settingTabs"
        :disabled="tabsDisabled"
      >
        <template #header>
          {{ $t('Web.DbImport.LabelSettings') }}
          <SlInfoButton
            v-tooltip="getTooltip($t('Web.DbImport.TooltipToImport'))"
          />
        </template>
      </SlTabList>
      <SlTabList
        v-model="tabModel"
        :tabs="importTabs"
        :disabled="tabsDisabled"
      >
        <template #header>
          {{ $t('ConnectorForm.Ui.btEditSQL') }}
        </template>
      </SlTabList>
      <SlTabList
        v-if="exportTabs.length"
        v-model="tabModel"
        :tabs="exportTabs"
        :disabled="tabsDisabled"
      >
        <template #header>
          {{ $t('Web.DbImport.LabelToExport') }}
        </template>
      </SlTabList>
    </template>
    <ConnectorContentWrapper
      :title="title"
      :is-valid-title="isValidTitle"
    >
      <template #actions>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          v-if="$sl_isEditingRoute"
          variant="secondary"
          @click="handleSaveSettings"
        >
          {{ $t('Common.Save') }}
        </SlButton>
        <SlButton @click="handleImport">
          {{ importLabel }}
        </SlButton>
      </template>
      <template>
        <SlTabContent
          v-for="tab in allTabs"
          :key="tab.value"
          :value="tab.value"
          :tab-value="tabModel"
        >
          <ValidationObserver
            :ref="`${tab.value}-observer`"
            tag="form"
            @submit.prevent.stop="handleTabSubmit"
          >
            <component
              :is="currentTabComponent(tab.component)"
              :stubs="tabStubs"
              :title="settingsTabTitle"
            />
          </ValidationObserver>
          <transition name="fade">
            <ConnectorContentBlock v-if="isPreviewVisible">
              <template #title>
                {{ previewTitle }}
              </template>
              <SlTable
                :id="`${tabModel}-preview`"
                :headers="previewHeaders"
                :lower-headers="previewSlots"
                :rows="previewRows"
                unique-key="id"
                header-unique-key="key"
                header-name-key="text"
                :max-height="previewTableMaxHeight"
                :loading="isPreviewLoading"
                :inner-no-data="!!previewHeaders.length && !previewTableName"
                inner-no-data-editable
                num-col
              >
                <template
                  v-for="(header, headerIndex) in previewHeaders"
                  #[`lower-header-${header.key}`]
                >
                  <SlTableSelect
                    :key="header.key"
                    :value="colSlotValue(headerIndex)"
                    :options="colSlotOptions(headerIndex)"
                    label="name"
                    track-by="colMeaning"
                    allow-empty
                    return-object
                    searchable
                    clearable
                    @input="(value) => handleUpdateColSlot(value, headerIndex)"
                  />
                </template>
                <template #before-outer>
                  <transition name="fade">
                    <div
                      v-if="previewLimitVisible"
                      class="rows-limit-message body-1 grey-60"
                    >
                      {{ $t('Web.DbImport.TablePreviewLimit', { 1: previewRowsLimit }) }}
                    </div>
                  </transition>
                </template>
                <template #no-data>
                  <SlNoData>
                    <template #text>
                      {{ noDataWarning }}
                    </template>
                  </SlNoData>
                </template>
              </SlTable>
            </ConnectorContentBlock>
          </transition>
        </SlTabContent>
      </template>
    </ConnectorContentWrapper>
    <template #right-col>
      <div
        v-if="connectionTitle"
        class="connection-info"
      >
        <div class="connection-info__header">
          <div class="connection-info__title body-4-md grey-60">
            {{ connectionTitle }}
          </div>
          <template v-if="serverName">
            <div class="connection-info__servername body-2 grey-90">
              <icon
                data="@icons/server.svg"
                class="fill-off size-20"
              />
              <span v-tooltip="getTooltip(serverName)">
                {{ serverName }}
              </span>
            </div>
            <div class="connection-info__username body-3 grey-60">
              {{ username }}
            </div>
          </template>
          <SlButton
            size="md"
            variant="secondary"
            color="grey"
            full-width
            @click="handleReconnect"
          >
            {{ $t('Web.BaseConnectors.BtnReconnect') }}
          </SlButton>
        </div>
        <div
          v-if="tables.length"
          class="connection-info__main"
        >
          <div class="connection-info__title body-4-md grey-60">
            {{ $t('Web.DbImport.LabelDbTables') }}
            <SlInfoButton
              v-tooltip="getTooltip($t('Web.DbImport.TooltipDbTables'))"
            />
            <SlButton
              size="xs"
              variant="secondary"
              color="grey"
              class="connection-info__title--refresh"
              icon
              @click="fetchTablesList"
            >
              <icon
                data="@icons/refresh.svg"
                class="fill-off size-16"
                :class="{
                  'spin-reverse': isTablesRefreshing
                }"
              />
            </SlButton>
          </div>
          <RecycleScroller
            id="database-tables-list"
            class="tables-list"
            :items="tables"
            :buffer="tablesListBuffer"
            :item-size="tablesListItemHeight"
          >
            <template v-slot="{ item }">
              <li class="tables-list__item">
                <div
                  :title="item"
                  class="tables-list__item-text body-1 grey-70"
                  draggable="true"
                  @dragstart="handleTableDragstart($event, item)"
                >
                  {{ item }}
                </div>
                <div class="tables-list__item-icons">
                  <SlButton
                    :title="$t('Web.DbImport.TooltipCopy')"
                    size="xs"
                    variant="text"
                    color="grey"
                    icon
                    @click="copyToClipboard(item)"
                  >
                    <icon
                      data="@icons/copy.svg"
                      class="fill-off size-16"
                    />
                  </SlButton>
                  <SlButton
                    :title="$t('Web.DbImport.TooltipPreview')"
                    size="xs"
                    variant="text"
                    color="grey"
                    icon
                    @click="handlePreviewTable(item)"
                  >
                    <icon
                      data="@icons/eye.svg"
                      class="fill-off size-16"
                    />
                  </SlButton>
                </div>
              </li>
            </template>
          </RecycleScroller>
        </div>
      </div>
    </template>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConnectorContentWrapper from '@/components/Connections/Common/ConnectorContentWrapper';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import Required from '@/components/Connections/DbRelated/Common/Required';
import Transactions from '@/components/Connections/DbRelated/Common/Transactions';
import ItemInfo from '@/components/Connections/DbRelated/Common/ItemInfo';
import ChannelInfo from '@/components/Connections/DbRelated/Common/ChannelInfo';
import OrdersToReceive from '@/components/Connections/DbRelated/Common/OrdersToReceive';
import OrdersToShip from '@/components/Connections/DbRelated/Common/OrdersToShip';
import Substitutions from '@/components/Connections/DbRelated/Common/Substitutions';
import BOM from '@/components/Connections/DbRelated/Common/BOM';
import Promotions from '@/components/Connections/DbRelated/Common/Promotions';
import Expirations from '@/components/Connections/DbRelated/Common/Expirations';
import InventoryParameters from '@/components/Connections/DbRelated/Common/InventoryParameters';
import Suppliers from '@/components/Connections/DbRelated/Common/Suppliers';
import MinMax from '@/components/Connections/DbRelated/Common/MinMax';
import InventoryReport from '@/components/Connections/DbRelated/Common/InventoryReport';
import InventoryForecast from '@/components/Connections/DbRelated/Common/InventoryForecast';
import Reports from '@/components/Connections/DbRelated/Common/Reports';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import { metaInfo } from '@/mixins/metaInfo';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import {
  dataFields,
  importTabKeys,
  exportTabKeys,
  exportTabs,
  importTabs,
  settingTabs,
  tabKeys
} from '@/config/connection/dbRelated.config';
import { connectionTypes, connectorByType } from '@/config/connection';
import { dbRequiredTabs } from '@/config/connection/database.config';
import { updateFgsFlags } from '@/config/api/operations.config';
import { HISTORICAL_TRANSACTIONS_TEMPLATE_URL } from '@/config/shared/resources.config';
import { formatTableName } from '@/helpers/sql';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { getColumnSlotOptions } from '@/helpers/connection';
import { copyToClipboard } from '@/helpers/utils/clipboard';
import { getTooltip } from '@/helpers/shared/tooltip';
import { $amp_logEvent } from '@/plugins/product/amplitude';

export default {
  name: 'DbRelated',
  components: {
    ConnectorContentBlock,
    ConnectorContentWrapper,
    Required,
    Transactions,
    ItemInfo,
    ChannelInfo,
    OrdersToReceive,
    OrdersToShip,
    Substitutions,
    BOM,
    Promotions,
    Expirations,
    InventoryParameters,
    Suppliers,
    MinMax,
    InventoryReport,
    InventoryForecast,
    Reports
  },
  mixins: [modal, fileSaver, metaInfo],
  data() {
    return {
      modalsId,
      tabModel: tabKeys.REQUIRED,
      isValidTitle: true,
      isTablesRefreshing: false,
      isProcessing: false,
      isRedirect: false,
      isEdited: false,
      tabsDisabled: false,
      previewTableName: '',
      previewTableMaxHeight: 530,
      previewRowsLimit: 100,
      tablesListBuffer: 200,
      tablesListItemHeight: 25,
      getTooltip
    };
  },
  provide() {
    return {
      getTooltip: this.getTooltip,
      setSettings: this.setSettings,
      historicalTransactionsTemplate: HISTORICAL_TRANSACTIONS_TEMPLATE_URL
    };
  },
  computed: {
    ...mapState({
      connectorState: state => state.dbRelated,
      commonData: state => state.dbRelated.commonData,
      cacheId: state => state.dbRelated.cache_id,
      username: state => state.dbRelated[dataFields.CONNECTION_DATA].username || '',
      connectionDataByType: state => state.connection.connectionData || {},
      currentConnector: state => state.connection.selectedConnector,
      tablePreview: state => state.dbRelated.table_preview || {},
      tables: state => state.dbRelated.tables_list || []
    }),
    ...mapGetters({
      projectLabel: 'project/projectLabel',
      importDataByTab: 'dbRelated/importDataByTab',
      serverName: 'dbRelated/serverName'
    }),
    connectorByType() {
      return connectorByType(this);
    },
    connectorType() {
      return this.connectorState.commonData.type;
    },
    slProjectName() {
      return this.connectionDataByType[this.currentConnector]?.commonData?.slProjectName || '';
    },
    connectorData() {
      return this.connectorByType[this.currentConnector];
    },
    connectionTitle() {
      return this.connectorData.title;
    },
    settingTabs() {
      return this.addRequiredTabs(this.filterTabs(settingTabs(this)));
    },
    importTabs() {
      return this.addRequiredTabs(this.filterTabs(importTabs(this)));
    },
    exportTabs() {
      return this.filterTabs(exportTabs(this));
    },
    allTabs() {
      return [
        ...this.settingTabs,
        ...this.importTabs,
        ...this.exportTabs
      ];
    },
    settingsTabTitle() {
      const currentTabConfig = this.settingTabs.find((tab) => tab.value === this.tabModel);

      return currentTabConfig?.title || '';
    },
    requiredTabs() {
      return this.importTabs.filter(tab => tab.required);
    },
    filledImportTabs() {
      return this.importTabs.filter(({ value }) => this.importDataByTab(value).query);
    },
    tabData() {
      return this.importDataByTab(this.tabModel);
    },
    tabStubs() {
      return this.tabData.stubs || null;
    },
    preview() {
      if (this.previewTableName) {
        return this.tablePreview;
      }

      return this.tabData.importPreview;
    },
    previewHeaders() {
      return this.preview?.headers || [];
    },
    previewRows() {
      return this.preview?.rows || [];
    },
    previewSlots() {
      // pass same headers again to sync col keys
      return this.tabData?.availableSlots && !this.previewTableName
        ? this.previewHeaders
        : null;
    },
    previewTitle() {
      return this.previewTableName
        ? this.$t('Web.DbImport.TitlePreviewTable', { 1: this.previewTableName })
        : this.$t('Web.DbImport.TitlePreviewQuery');
    },
    previewLimitVisible() {
      return this.previewRows?.length >= this.previewRowsLimit;
    },
    noDataWarning() {
      const title = this.$t('Web.DbImport.TextQueryNoData');
      const text = this.previewTableName
        ? this.$t('Web.DbImport.TextNoDataTablePreview', { 1: this.previewTableName })
        : this.$t('Web.DbImport.TextNoDataTableQuery');

      return `${title} \n${text}`;
    },
    isPreviewLoading() {
      return this.preview?.isLoading;
    },
    isPreviewLoaded() {
      return this.preview?.isLoaded;
    },
    isPreviewVisible() {
      return this.isPreviewLoading || this.isPreviewLoaded;
    },
    title() {
      if (this.$sl_isEditingRoute) {
        return this.$t('Web.BaseConnectors.TitleEdit', {
          1: this.projectLabel
        });
      }

      return this.slProjectName;
    },
    isConnectedToDatasource() {
      const fieldByConnector = {
        [connectionTypes.DATABASE]: this.tables,
        [connectionTypes.NETSUITE]: this.$route.params.conenctor === connectionTypes.NETSUITE,
        [connectionTypes.DYNAMICS_NAV]: this.tables,
        [connectionTypes.SAP]: this.tables,
        [connectionTypes.FISHBOWL]: this.tables,
        [connectionTypes.DYNAMICS_GP]: this.tables,
        [connectionTypes.ODOO]: this.tables
      };

      if (this.currentConnector === connectionTypes.NETSUITE) {
        return fieldByConnector[this.currentConnector];
      }

      return fieldByConnector[this.currentConnector]?.length;
    },
    requiredTabsByConnector() {
      const tabsByConnector = {
        [connectionTypes.DATABASE]: dbRequiredTabs
      };

      if (tabsByConnector[this.currentConnector]) {
        return tabsByConnector[this.currentConnector];
      }

      return [tabKeys.REQUIRED];
    },
    importLabel() {
      return this.$sl_isEditingRoute
        ? this.$t('Import.Dialog.Reimport')
        : this.$t('Import.Dialog.Import');
    }
  },
  watch: {
    tabModel(value) {
      const tabData = this.importDataByTab(value);

      if (exportTabKeys.includes(value) && !tabData.stubs?.length) {
        this.fetchExportStubs(value);
      }

      if (importTabKeys.includes(value) && !tabData?.availableSlots?.length) {
        this.fetchSlotsList(value);
      }

      this.setTablePreviewState({
        table: true,
        value: {}
      });
      this.previewTableName = '';
    },
    commonData: {
      handler(val) {
        this.requiredTabValidationWatcher(val);
      },
      deep: true
    },
    tabData: {
      handler(val) {
        this.requiredTabValidationWatcher(val);
      },
      deep: true
    },
    isEdited() {
      preventTabClose(true);
    }
  },
  async beforeMount() {
    try {
      this.isProcessing = true;

      if (!this.$sl_isEditingRoute) {
        await this.getDefaultSettings();
      }

      if (!this.cacheId) {
        return this.$router.push({
          name: routeNames.DEMAND
        });
      }

      if (this.isConnectedToDatasource) {
        await this.fetchFilledTabsSlots();

        return;
      }

      await this.testDatasourceConnection({
        isEdit: true,
        cacheId: this.cacheId
      });

      if (this.$sl_isEditingRoute) {
        await this.getConnectionInfo();
        await this.fetchFilledTabsSlots();
      }
    } catch {
      this.$router.push({
        name: routeNames.DEMAND
      });
    } finally {
      this.isProcessing = false;
    }
  },
  beforeDestroy() {
    preventTabClose();
  },
  destroyed() {
    this.resetState();
  },
  methods: {
    copyToClipboard,
    ...mapActions({
      fetchSlotsList: 'dbRelated/fetchSlotsList',
      fetchDatabaseTables: 'dbRelated/fetchDatabaseTables',
      fetchTablePreview: 'dbRelated/fetchTablePreview',
      setTablePreviewState: 'dbRelated/setTablePreviewState',
      setTablePreview: 'dbRelated/setTablePreview',
      exportToCSV: 'dbRelated/exportToCSV',
      downloadCsv: 'dbRelated/downloadCsv',
      fetchExportStubs: 'dbRelated/fetchExportStubs',
      testExport: 'dbRelated/testExport',
      setColSlot: 'dbRelated/setColSlot',
      resetState: 'dbRelated/resetState',
      updateTabSettings: 'dbRelated/updateTabSettings',
      testDbRelatedConnection: 'dbRelated/testConnection',
      testOdooConnection: 'dbRelated/testOdooConnection',
      testNetsuiteConnection: 'netsuite/testConnection',
      getDefaultSettings: 'dbRelated/getDefaultSettings',
      subscribe: 'operations/subscribe',
      importConnectionInfo: 'connection/importConnectionInfo',
      reimportConnectionInfo: 'connection/reimportConnectionInfo',
      saveConnectionInfo: 'connection/saveConnectionInfo',
      getConnectionInfo: 'connection/getConnectionInfo',
      logout: 'user/logout'
    }),
    // calls from wrapper
    beforeRouteLeaveGuard(to, from, next) {
      if (this.isRedirect) {
        return next();
      }

      if (this.isEdited) {
        return this.showExitConfirm(next);
      }

      next();
    },
    async requiredTabValidationWatcher() {
      if (this.tabModel === tabKeys.REQUIRED) {
        const valid = await this.validateTab();

        this.tabsDisabled = !valid;
      }
    },
    testDatasourceConnection(payload) {
      const actionByConnector = {
        [connectionTypes.NETSUITE]: this.testNetsuiteConnection,
        [connectionTypes.ODOO]: this.testOdooConnection
      };

      if (actionByConnector[this.currentConnector]) {
        return actionByConnector[this.currentConnector](payload);
      }

      return this.testDbRelatedConnection(payload);
    },
    currentTabComponent(component) {
      return this.connectorData.components[this.tabModel] || component;
    },
    isTabVisible({ component, value }) {
      const dynamic = this.connectorData.components[value];
      const common = Object.keys(this.$options.components)
        .some(existingComponent => {
          return existingComponent === component;
        });

      if (dynamic === null) {
        return false;
      }

      return common || dynamic;
    },
    filterTabs(tabs) {
      return tabs.filter(tab => this.isTabVisible(tab));
    },
    addRequiredTabs(tabs) {
      return tabs.map(tab => ({
        ...tab,
        required: this.requiredTabsByConnector.includes(tab.value)
      }));
    },
    setSettings(payload) {
      if (!this.isEdited) {
        this.isEdited = true;
      }

      this.updateTabSettings({
        tab: this.tabModel,
        ...payload
      });
    },
    fetchFilledTabsSlots() {
      const slotRequests = importTabKeys.reduce((acc, key) => {
        const matchedSlots = Object.keys(this.connectorState[key].matchedSlots);

        if (matchedSlots.length) {
          acc.push(this.fetchSlotsList(key));
        }

        return acc;
      }, []);

      return Promise.allSettled(slotRequests);
    },
    async fetchTablesList() {
      try {
        this.isTablesRefreshing = true;

        await this.fetchDatabaseTables();
      } catch (e) {
        this.$notify({
          type: 'error',
          text: e?.message,
          duration: 7000
        });
      } finally {
        this.isTablesRefreshing = false;
      }
    },
    getQueryPayload(table = null) {
      return {
        tab: this.tabModel,
        table
      };
    },
    showExitConfirm(next) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        icon: 'style_save_double',
        title: this.$t('Web.Modals.UnsavedConfirm.TitleLeaveProject'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextLeaveProject'),
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonLeave'),
        confirmCallback: () => {
          this.isRedirect = true;
          this.isEdited = false;

          next();
        }
      });
    },
    handleReconnect() {
      this.showModal(this.connectorData.modal, { isNew: false });
    },
    handleCancel() {
      if (!this.isEdited) {
        return this.goBack();
      }

      this.showExitConfirm(this.goBack);
    },
    handleTableDragstart(e, table) {
      e.dataTransfer.setData('text/plain', formatTableName(table));
    },
    validateTab() {
      const observer = this.$refs[`${this.tabModel}-observer`]?.[0];

      if (!observer) {
        return true;
      }

      return observer.validate();
    },
    async handleTabSubmit(e) {
      const valid = await this.validateTab();

      if (!valid || !e.submitter) {
        return;
      }

      if (e.submitter.hasAttribute(['data-test-export'])) {
        return this.handleTestExport();
      }

      if (e.submitter.hasAttribute(['data-export'])) {
        return this.handleExportToCSV();
      }

      if (e.submitter.hasAttribute(['data-preview'])) {
        return this.handlePreviewTable();
      }
    },
    async handlePreviewTable(table = '') {
      const payload = this.getQueryPayload(table);

      try {
        this.previewTableName = table;

        this.setTablePreviewState({
          ...payload,
          value: {
            isLoading: true
          }
        });

        const { operationData } = await this.subscribe({
          subscriber: () => this.fetchTablePreview(payload)
        });

        this.setTablePreview({
          ...payload,
          table: operationData,
          isSql: !table
        });
      } catch (e) {
        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            text: errorMessage,
            duration: 15000
          });
        }

        this.setTablePreviewState({
          ...payload,
          value: {
            isLoading: false
          }
        });
      }
    },
    async handleExportToCSV() {
      try {
        const { operationData } = await this.subscribe({
          subscriber: () => this.exportToCSV(this.getQueryPayload())
        });

        this.saveFile(
          this.downloadCsv.bind(this, { fileId: operationData.id }),
          {
            errorTitle: this.$t('Error.HeadDb'),
            notifyType: 'notify'
          }
        );
      } catch (e) {
        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            text: errorMessage,
            duration: 15000
          });
        }
      }
    },
    async handleTestExport() {
      try {
        const result = await this.testExport(this.tabModel);

        if (result) {
          this.$notify({
            type: 'success',
            title: this.$t('Web.Notification.TitleExportSuccess')
          });
        }
      } catch (e) {
        if (!e?.message) {
          return;
        }

        this.$notify({
          type: 'error',
          title: this.$t('Error.HeadDb'),
          text: e.message,
          duration: -1
        });
      }
    },
    colSlotOptions(colIndex) {
      return getColumnSlotOptions(this.tabData, colIndex);
    },
    colSlotValue(index) {
      return this.tabData.matchedSlots[index];
    },
    handleUpdateColSlot(slotValue, colIndex) {
      this.setColSlot({
        tab: this.tabModel,
        value: slotValue,
        index: colIndex
      });
    },
    checkUnfilledTab(tabs) {
      if (this.tabsDisabled) {
        return this.settingTabs[0];
      }

      return tabs.find(tab => !(this.importDataByTab(tab.value).query));
    },
    checkUnmatchedSlot(tabs) {
      let result = null;

      tabs.find(({ value: tabKey }) => {
        const tabData = this.importDataByTab(tabKey);
        const matchedSlots = Object.values(tabData.matchedSlots);
        const unmatchedSlots = tabData.availableSlots.reduce((acc, slot) => {
          if (!slot.isRequired) {
            return acc;
          }

          if (!matchedSlots.includes(slot.colMeaning)) {
            acc.push(slot.name);
          }

          return acc;
        }, []);

        if (unmatchedSlots.length) {
          result = {
            tab: tabKey,
            slots: unmatchedSlots
          };

          return true;
        }

        return false;
      });

      return result;
    },
    async validateFilledTabs() {
      this.tabModel = tabKeys.REQUIRED;

      await this.$nextTick();

      const isValid = await this.validateTab();

      if (!isValid) {
        return false;
      }

      const unfilledTab = this.checkUnfilledTab(this.requiredTabs);
      const unmatchedSlots = this.checkUnmatchedSlot(this.filledImportTabs);

      if (!unfilledTab && !unmatchedSlots) {
        return true;
      }

      if (unfilledTab) {
        if (this.tabsDisabled) {
          return;
        }

        this.tabModel = unfilledTab.value;
        this.$nextTick(this.validateTab);

        this.$notify({
          type: 'error',
          title: this.errorNotifyTitle,
          text: this.$t('Web.DbImport.NotifyFillQueryWarningText', { 1: unfilledTab.label }),
          duration: -1
        });

        return false;
      }

      if (unmatchedSlots) {
        this.tabModel = unmatchedSlots.tab;

        this.$notify({
          type: 'error',
          title: this.errorNotifyTitle,
          text: this.$t('Web.Spreadsheet.NotifyMatchColsWarningText', { 1: unmatchedSlots.slots.join(', ') }),
          duration: -1
        });

        return false;
      }
    },
    async handleImport() {
      const valid = await this.validateFilledTabs();

      this.isValidTitle = !!this.title;

      if (!valid || !this.isValidTitle) {
        return;
      }

      try {
        this.isProcessing = true;

        const action = this.$sl_isEditingRoute ? this.reimportConnectionInfo : this.importConnectionInfo;

        const { subscriberData } = await this.subscribe({
          subscriber: () => action({ data: this.connectorState }),
          ignoreLastChanges: !this.$sl_isEditingRoute,
          ui_flags: async({ operationId, flags }) => {
            if (flags.includes(updateFgsFlags.IMPORT_META_INFO)) {
              const metaInfo = await this.fetchMetaInfo(operationId);

              this.handleImportMetaInfo(metaInfo, operationId);
            }
          }
        });

        this.isRedirect = true;

        if (!this.$sl_isEditingRoute) {
          $amp_logEvent('Project Created');

          return this.$store.dispatch('manageProjects/openProject', {
            ...subscriberData
          });
        } else {
          this.$notify({
            type: 'success',
            title: this.$t('Web.UpdateData.Success')
          });
        }

        this.$router.push({
          name: routeNames.DEMAND
        });
      } catch (e) {
        const errorMessage = e?.message?.error?._fallback || e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            title: this.$t('Web.DbImport.Errors.NotifyTitleImport'),
            text: errorMessage,
            duration: -1
          });
        }

        this.logout({
          e,
          from: `${this.connectorType}/handleImport`
        });
      } finally {
        this.isProcessing = false;
      }
    },
    async handleSaveSettings() {
      const valid = await this.validateFilledTabs();

      if (!valid) {
        return;
      }

      try {
        const response = await this.saveConnectionInfo({ data: this.connectorState });

        if (response?.data?.successful) {
          this.isEdited = false;

          this.$notify({
            type: 'success',
            title: this.$t('Web.Notification.TitleSavedSuccess')
          });

          this.isRedirect = false;

          this.$router.push({
            name: routeNames.DEMAND
          });
        }
      } catch (e) {
        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            title: this.$t('Web.DbImport.Errors.NotifyTitleImport'),
            text: errorMessage,
            duration: -1
          });
        }
      }
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/connections/connectors/db-related/index.scss";
</style>
